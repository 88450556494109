const uz = {
  Groups: {
    title: "Guruhlar",
  },
  GroupList: {
    group: "Guruh",
    dates: "Darslar davri",
    report: "Darslar hisobotlari",
    statistics: "Platforma statistikasi",
    progress: "Platforma rivojlanishi",
    lessonsCount: "O'tkazilgan darslar",
    total: "Jami AK soat",
    finished: "O'tkazilgan AK soat",
    left: "Qolgan AK soat",
    period: "Davomiyligi",
    teachers: "O'qituvchilar",
    attendance: "Davomat",
  },
  ModalGroupReport: {
    Download: "Yuklash",
    Teacher: "O'qituvchi",
    Course: "Kurs",
    Schedule: "Jadval",
    Hours: "soat",
    TotalHours: "Jami AK soat",
    CompletedHours: "O'tkazilgan AK soat",
    LeftHours: "Qolgan AK soat",
    Compensation: "Kompensatsiya",
    Students: "Talabalar",
    Duration: "Davomiyligi",
  },
  GroupFilter: {
    ActiveGroups: "Faol guruhlar",
    ActiveGroupsNo: "Yo'q",
    ActiveGroupsYes: "Ha",
    SelectLevel: "Darajani tanlash",
    SearchStudent: "Talaba qidirish",
    SearchGroup: "Guruh qidirish",
    SelectModule: "Modulni tanlash",
    AllModules: "Barcha modullar",
    AllCompanies: "Barcha kompaniyalar",
    SelectCompany: "Kompaniyani tanlash",
    StartDate: "Boshlanish sanasi",
    EndDate: "Tugash sanasi",
    WithLessonsWithoutTeachers: "O'qituvchisiz",
    WithLessonsOnlyTechnicalCancellationBefore: "Bekor qilish",
  },
  GroupDownload: {
    Download: "Yuklash",
    Groups: "Guruhlar",
    WithoutPrice: "Narxsiz guruhlar",
  },
  GroupsAutocompleteFilter: {
    EnterGroupName: "Guruh nomini kiriting",
    SelectGroup: "Guruhni tanlash",
  },
  GroupsEmptyTable: {
    FirstTextDefault: "Sizga ta'lim modullari tayinlanmagan.",
    SecondTextDefault: "O'qish koordinatoringizga murojaat qiling.",
    FirstTextDistributor: "Ko'rsatish uchun ma'lumotlar yo'q.",
    SecondTextDistributor: "Qandaydir ma'lumotlarni kiritib ko'ring.",
    FirstTextClasses: "Ko'rsatish uchun ma'lumotlar yo'q.",
    SecondTextClasses:
      "Filtrlarni o'zgartiring yoki qo'llab-quvvatga murojaat qiling.",
  },
  TeachersAutocompleteFilter: {
    EnterTeacherName: "Hech qanday o'qituvchi topilmadi",
    SelectTeacher: "O'qituvchining tanlovi",
  },
  ShortWeekDay: {
    "0": "Yak.",
    "1": "Dush.",
    "2": "Sesh.",
    "3": "Chor.",
    "4": "Paysh.",
    "5": "Jum.",
    "6": "Shan.",
  },
};

export default uz;
