const en = {
  Groups: {
    title: "Groups",
  },
  GroupList: {
    group: "Group",
    dates: "Class period",
    report: "Class report",
    statistics: "Platform statistics",
    progress: "Platform progress",
    lessonsCount: "Classes Conducted",
    total: "Hours total",
    finished: "Hours completed",
    left: "Hours left",
    period: "Period",
    teachers: "Teachers",
    attendance: "Attendance",
  },
  ModalGroupReport: {
    Download: "Download",
    Teacher: "Teacher",
    Course: "Course",
    Schedule: "Schedule",
    Hours: "ac.h.",
    TotalHours: "Hours total",
    CompletedHours: "Hours completed",
    LeftHours: "Hours left",
    Compensation: "Compensation",
    Students: "Students",
    Duration: "Duration",
  },
  GroupFilter: {
    Company: "Company",
    ActiveGroups: "Active groups",
    ActiveGroupsNo: "No",
    ActiveGroupsYes: "Yes",
    SelectLevel: "Select level",
    SearchStudent: "Search student",
    SearchGroup: "Search group",
    SelectModule: "Select module",
    AllModules: "All modules",
    StartDate: "Start date",
    EndDate: "End date",
    WithLessonsWithoutTeachers: "Without a teacher",
    WithLessonsOnlyTechnicalCancellationBefore: "Cancellations",
  },
  GroupDownload: {
    Download: "Download",
    Groups: "Groups",
    WithoutPrice: "Groups without price",
  },
  GroupsAutocompleteFilter: {
    EnterGroupName: "Enter group name",
    SelectGroup: "Group select",
  },
  GroupsEmptyTable: {
    FirstTextDefault: "There are no groups for you.",
    SecondTextDefault: "Please contact your study coordinator.",
    FirstTextDistributor: "There is no data to be displayed.",
    SecondTextDistributor: "Try to enter some data.",
    FirstTextClasses: "There is no data to be displayed.",
    SecondTextClasses: "Try changing filters or contact support.",
  },
  TeachersAutocompleteFilter: {
    EnterTeacherName: "Teachers not found",
    SelectTeacher: "Teacher select",
  },
  ShortWeekDay: {
    "0": "Sun.",
    "1": "Mon.",
    "2": "Tue.",
    "3": "Wed.",
    "4": "Thu.",
    "5": "Fri.",
    "6": "Sat.",
  },
};

export default en;
