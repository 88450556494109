import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import i18nAttendance from "@packages/attendance/locales/i18n";
import i18nCreativeTasks from "@packages/creative-tasks/locales/i18n";
import i18nExercises from "@packages/exercises/locales/i18n";
import i18nGroups from "@packages/groups/locales/i18n";

import { resources } from "./resources";

export const selectedLanguague: string | null = localStorage.getItem("lang")
  ? localStorage.getItem("lang")
  : "ru";

export const switchLang = (lang: string): void => {
  localStorage.setItem("lang", lang);
  i18n.changeLanguage(lang || "en");
};

export function initI18n(): void {
  i18n.use(initReactI18next).init({
    resources,
    lng: selectedLanguague || "",
    interpolation: {
      escapeValue: false,
    },
    nsSeparator: ":",
  });
}

i18n.on("languageChanged", (lng) => {
  i18nAttendance.changeLanguage(lng);
  i18nCreativeTasks.changeLanguage(lng);
  i18nExercises.changeLanguage(lng);
  i18nGroups.changeLanguage(lng);
});
