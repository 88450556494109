import {
  Autocomplete,
  Box,
  CircularProgress,
  TextField,
  FormControlLabel,
  Switch,
  Typography,
  Stack,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { Controller, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { ValueWithLabel } from "./types";
import { levels } from "../../constants";
import { Filters } from "../../Groups";
import { getAutocompleteFieldValue } from "../../helpers/getAutocompleteFieldValue";
import { FilterSelect } from "../FilterSelect";
import { SearchFilter } from "../SearchFilter";
import { TeachersAutocompleteFilter } from "../TeachersAutocompleteFilter";

export interface GroupFilterProps {
  methods: UseFormReturn<Filters>;
  showCompanyFilter: boolean;
  showSecondaryFilters: boolean;
  showTeacherFilter: boolean;
  showWithoutTeachersFilter: boolean;
  showAboveTwoLessonsEarlyCanceledBySchoolInLastMonth: boolean;
  listModules: ValueWithLabel[];
  listCompanies: ValueWithLabel[];
  coursesLoading?: boolean;
}

export const GroupFilter = observer(
  ({
    methods,
    showCompanyFilter,
    showSecondaryFilters,
    showTeacherFilter,
    showWithoutTeachersFilter,
    showAboveTwoLessonsEarlyCanceledBySchoolInLastMonth,
    listModules,
    listCompanies,
    coursesLoading = false,
  }: GroupFilterProps): JSX.Element => {
    const { t } = useTranslation();

    const { watch, register, control, setValue } = methods;

    const data = watch();

    return (
      <Stack direction="column" spacing={3} mb="1rem">
        <Stack
          direction="row"
          flexWrap="wrap"
          alignItems="flex-end"
          spacing={2}
        >
          {showCompanyFilter && (
            <Controller
              name="company"
              control={control}
              render={({ field: { onChange } }) => (
                <Autocomplete
                  disablePortal
                  openOnFocus
                  onChange={(_, v) => onChange(v?.value)}
                  options={listCompanies}
                  value={getAutocompleteFieldValue(listCompanies, data.company)}
                  sx={{ minWidth: 200, marginTop: "0.5rem" }}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      variant="standard"
                      placeholder={t("GroupFilter:SelectCompany")}
                    />
                  )}
                />
              )}
            />
          )}
          <SearchFilter
            control={control}
            name="group"
            inputProps={{
              placeholder: t("GroupFilter:SearchGroup") ?? "",
            }}
          />
          {showTeacherFilter && (
            <TeachersAutocompleteFilter
              teacherId={data.teacher}
              onChange={(id) => setValue("teacher", id)}
            />
          )}
        </Stack>

        <Stack direction="row" flexWrap="wrap" spacing={2}>
          {showWithoutTeachersFilter && (
            <Controller
              name="withoutTeachers"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Switch checked={field.value} onChange={field.onChange} />
                  }
                  label={
                    <Typography variant="regularText">
                      {t("GroupFilter:WithLessonsWithoutTeachers")}
                    </Typography>
                  }
                />
              )}
            />
          )}

          {showAboveTwoLessonsEarlyCanceledBySchoolInLastMonth && (
            <Controller
              name="aboveTwoLessonsEarlyCanceledBySchoolInLastMonth"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Switch checked={field.value} onChange={field.onChange} />
                  }
                  label={
                    <Typography variant="regularText">
                      {t(
                        "GroupFilter:WithLessonsOnlyTechnicalCancellationBefore"
                      )}
                    </Typography>
                  }
                />
              )}
            />
          )}
        </Stack>

        {showSecondaryFilters && (
          <Stack
            direction="row"
            flexWrap="wrap"
            alignItems="flex-end"
            spacing={2}
          >
            <FilterSelect
              data={levels}
              placeholder={t("GroupFilter:SelectLevel")}
              value={data.level}
              {...register("level")}
            />
            <SearchFilter
              control={control}
              name="student"
              inputProps={{
                placeholder: t("GroupFilter:SearchStudent") ?? "",
              }}
            />
            <Box position="relative">
              <FilterSelect
                data={listModules}
                placeholder={t("GroupFilter:SelectModule")}
                value={data.module}
                {...register("module")}
              />
              {coursesLoading && (
                <Box
                  position="absolute"
                  width="100%"
                  height="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  top={0}
                  left={0}
                >
                  <CircularProgress size={25} />
                </Box>
              )}
            </Box>
          </Stack>
        )}
      </Stack>
    );
  }
);
