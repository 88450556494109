/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { GeneralApiProblem } from "./ApiProblem";
import { ClassGroup } from "../../models/ClassGroup/ClassGroup";
import { ClassGroupRateSnapshot } from "../../models/ClassGroupRate/ClassGroupRate";
import { ExerciseResultCorrection } from "../../models/ExerciseResult/ExerciseResultCorrection";
import { ExerciseResultError } from "../../models/ExerciseResult/ExerciseResultError";
import { LearningGroup } from "../../models/LearningGroups/LearningGroupModel";
import { Lesson } from "../../models/Lesson/LessonModel";
import { Teacher } from "../../models/Teacher/Teacher";
import { UnitSnapshot } from "../../models/Unit/UnitModel";
import { UserSnapshot } from "../../models/User/User";

export enum WordVocabularySource {
  glossary = 1,
  teacher = 2,
  creativeExerciseMistake = 3,
  vocabularyBlockMistake = 4,
  custom = 5,
}

export type LoginUser =
  | { kind: "ok"; user: Omit<UserSnapshot, "isTeacher"> }
  | GeneralApiProblem;

export type LoginByToken = { kind: "ok" } | GeneralApiProblem;

export type ChangePassword = { kind: "ok" } | GeneralApiProblem;

export type RestorePassword = { kind: "ok" } | GeneralApiProblem;

export type GetCourses = { kind: "ok"; courses: any } | GeneralApiProblem;

export type GetSummary = { kind: "ok"; summary: any } | GeneralApiProblem;

export type SaveRecoding =
  | { kind: "ok"; data: SavedRecording }
  | GeneralApiProblem;

export type PreprocessSpeakingQuestion =
  | { kind: "ok"; data: PreprocessedSpeakingQuestion }
  | GeneralApiProblem;

export type GetLearningGroups =
  | {
      kind: "ok";
      data: { learningGroups: LearningGroup[]; classGroups?: ClassGroup[] };
    }
  | GeneralApiProblem;

export type GetUnits =
  | { kind: "ok"; units: UnitSnapshot[] }
  | GeneralApiProblem;

export type GetClassGroupRates =
  | { kind: "ok"; rates: ClassGroupRateSnapshot[] }
  | GeneralApiProblem;

export type GetWordTranslation =
  | { kind: "ok"; data: WordTranslation }
  | GeneralApiProblem;

export type GetTeachets =
  | { kind: "ok"; teachers: Teacher[] }
  | GeneralApiProblem;

export type GetBlocks = { kind: "ok"; blocks: any } | GeneralApiProblem;

export type AddWords = { kind: "ok"; data: AddedWord[] } | GeneralApiProblem;

export type GetCountries = { kind: "ok"; data: Country[] } | GeneralApiProblem;

export type GetCities = { kind: "ok"; data: City[] } | GeneralApiProblem;

export type GetDegrees = { kind: "ok"; data: Degree[] } | GeneralApiProblem;

export type GetLearningGroupsParams = {
  ids?: string[];
  classGroupIds?: string[];
  prefetchedClassGroups?: any[];
  company?: string;
  name?: string;
  teacher?: string;
  unlicensed?: boolean;
  includeLessons?: boolean;
  page?: number;
  limit?: number;
  sortBy?: string;
  status?: "learning";
  studentIdToCutByExcludeDate?: string;
  withoutTeachers?: boolean;
  aboveTwoLessonsEarlyCanceledBySchoolInLastMonth?: boolean;

  classGroupsFromDate?: Date;
  classGroupsToDate?: Date;
};

export type GetLearningGroupsByLessonsStatusParams = {
  status: string;
  fromDate?: Date;
  toDate?: Date;
};

export type GetClassGroupsWithLessonsParams = {
  classGroupIds?: string[];
  status?: string;

  fromDate?: Date;
  toDate?: Date;
};

export type GetClassGroupRatesParams = {
  ids: string[];
};

export interface GetExersiceResults {
  isTeacher: boolean;
}

export interface GetExerciseResultDetails {
  id: string;
  isTeacher: boolean;
}

export interface UpdateExerciseResult {
  id: string;
  comment: string;
  errors?: ExerciseResultError[];
  removedErrors?: ExerciseResultError[];
  corrections?: ExerciseResultCorrection[];
  removedCorrections?: ExerciseResultCorrection[];

  isTeacher: boolean;
  isSubmit?: boolean;
}

export type GetLearningGroupApprovesParams = {
  groupIds?: string[];
};

export interface BlockApi {
  id: number;
  updated_at?: Date;
  children_list_updated_at?: Date;
  code?: string;
  translations_attributes?: TranslationsAttributeApi[];
  unit_id?: number;
  exercises_count?: number;
  time_limit?: number;
  order?: number;
  max_mark?: number;
  exercise_groups?: ExerciseGroup[];
  type?: Type;
}

export interface SavedRecording {
  questionId: string;
  recordUrl: string;
}

export interface PreprocessedSpeakingQuestion {
  closestCorrectAnswer: number | null;
  comparationPercent: boolean | null;
  recordText: string | null;
  recordUrl: string | null;
}

export interface ExerciseGroup {
  id: number;
  code: string;
  uuid: string;
}

export interface DictionaryWord {
  word: string;
  translations: string[];
}

export interface Definition {
  translations: string[];
  partOfSpeech: string;
}

export interface WordTranslation {
  dictionaryWord: DictionaryWord;
  definitions: Definition[];
  transcription: string;
}

export interface WordToAdd {
  heading: string;
  translation: string;
  transcription: string;
  language_from: string;
  language_to: string;
  part_of_speech: string;
}

export interface AddedWord {
  word: string;
  translation: string;
  partOfSpeech: string;
  addedBefore: boolean;
}

export interface BlockTranslationsAttribute {
  locale: Locale;
  name: string;
  description: null;
}

export interface PivotReportSchedule {
  day: string;
  startTime: string;
  endTime: string;
}

export interface UserVisitsData {
  url: string;
  userId: string | number;
}

export interface UserActivityData {
  courseId: number | string;
  pageTypeId: number;
  reportInterval: number;
  time: number;
  url: string;
  userId: string | number;
}

export interface PivotReportStudent {
  ids: number[];
  name: string;
}

export interface PivotReport {
  students: PivotReportStudent[];
  scheduleTimes: PivotReportSchedule[];
  lessons: Lesson[];
  duration: number;
}

export interface PivotReportClassPeriod {
  from: Date | null;
  to: Date | null;
}

export interface PivotReportStatistics {
  teacherName: string;
  schedules: PivotReportSchedule[];
  courses: string;
  classPeriod: PivotReportClassPeriod;
  totalHours: number;
  completedHours: number;
  leftHours: number;
  lateCustomerCancelHours: number;
  lateSchoolCancelHours: number;
  compensationHours: number;
  earlyCustomerCancelHours: number;
  earlySchoolCancelHours: number;
  students: string[];
}

export type WordFilterType = "learned" | "all" | "unlearned";

export interface WordFilter {
  fromUnit: boolean;
  addedToMe: boolean;
  selectedCourse: string;
  selectedUnit: string;
  selectedType: WordFilterType;
}

export interface Student {
  id?: number;
  firstName?: string;
  lastName?: string;
}

export interface Attendance {
  studentId: number | null;
  attended: boolean | null;
  comment: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface ValueWithLabel {
  label: string;
  value: string;
}

export enum DistributorReportType {
  students = "Reports::StudentsReport",
  teachers = "Reports::TeachersReport",
  lesson = "Reports::Lessons",
}

export interface CreateDistributorReportData {
  title: string;
  type: string;
  groups: string[];
  from: string | null;
  to: string | null;
}

export enum Locale {
  En = "en",
  Ru = "ru",
}

export interface Type {
  id: number;
  code: string;
  translations_attributes: TranslationsAttributeApi[];
}

export interface TranslationsAttributeApi {
  locale: Locale;
  name: string;
}

export interface CurrentUser {
  id: number;
  last_name: string;
  first_name: string;
  person_id: null;
  created_at: Date;
  updated_at: Date;
  organization_id: null;
  locale: string;
  country: null;
  city: null;
  time_zone: null;
  vip_type: null;
  about: null;
  email: string;
  has_oauth_identity: boolean;
  trusted: null;
  how_do_you_know: null;
  canvas_id: null;
  deleted_at: null;
  sector: null;
  company_name: null | string;
  position: null;
  last_distributor_id: null;
  last_institution_id: null;
  last_customer_company_id: null;
  last_distributor_timestamp: null;
  last_institution_timestamp: null;
  last_customer_company_timestamp: null;
  job_position: null;
  teacher_max_load: number;
  teacher_is_active: boolean;
  teacher_studies_types: null;
  teacher_languages_count: number;
  change_password: boolean;
  teacher_learning_groups_count: number;
  middle_name: string;
  all_content_available: boolean;
  authentication_token: null;
  knowledge_level_id: null;
  video_tutorial_shown: boolean;
  last_visit_at: null;
}

// TEACHER COURSES

export interface TeacherCourse {
  available_courses: AvailableCourse[];
  last_sync_at: null;
  user: User;
}

export interface LessonCredentials {
  login: string;
  password: string;
}

export interface LessonUpdateModel {
  status?: string;
  date?: Date;
  timeZone?: string;
  duration?: number;
  comment?: string | null;
  actualUnitId?: number;
  attendance?: Attendance[];
}

export interface LessonUpdateData {
  status?: string;
  startAt?: string;
  startTime?: string;
  duration?: number;
  comment?: string | null;
  teacherIds?: number[];
  webinarRoomLink?: string | null;
  hostId?: string | null;
  webinarRecordLink?: string | null;
  attendance?: Attendance[];
  actualUnitId?: number;
}

export interface LessonUpdate {
  id: string;
}

export interface AvailableCourse {
  id: number;
  code: string;
  publisher: string;
  language_id: number;
  knowledge_level_id: number;
  knowledge_area_id: number;
  deleted_at: unknown;
  created_at: Date;
  updated_at: Date;
  max_mark: number;
  distributor_id: number;
  is_for_testing: boolean;
  picture_id: number;
  time_limit: number;
  passing_threshold: unknown;
  passing_threshold_percent: unknown;
  order: number;
  uuid: string;
  children_list_updated_at: Date;
  show_in_course_list: boolean;
  duration: unknown;
  tags: string[];
  name: string;
  short_name: string;
  description: unknown;
}

export interface User {
  id: number;
  last_name: string;
  first_name: string;
  person_id: unknown;
  created_at: Date;
  updated_at: Date;
  organization_id: unknown;
  locale: string;
  country: unknown;
  city: unknown;
  time_zone: unknown;
  vip_type: unknown;
  about: unknown;
  email: string;
  has_oauth_identity: boolean;
  trusted: unknown;
  how_do_you_know: unknown;
  canvas_id: unknown;
  deleted_at: unknown;
  sector: unknown;
  company_name: unknown;
  position: unknown;
  last_distributor_id: unknown;
  last_institution_id: unknown;
  last_customer_company_id: unknown;
  last_distributor_timestamp: unknown;
  last_institution_timestamp: unknown;
  last_customer_company_timestamp: unknown;
  job_position: unknown;
  teacher_max_load: number;
  teacher_is_active: boolean;
  teacher_studies_types: unknown;
  teacher_languages_count: number;
  change_password: boolean;
  teacher_learning_groups_count: number;
  middle_name: string;
  all_content_available: boolean;
  authentication_token: unknown;
  knowledge_level_id: unknown;
  video_tutorial_shown: boolean;
  last_visit_at: unknown;
}

export interface GenerateDistributorReportProps {
  dateFrom: Date;
  dateTo: Date;
  type?: string;
  name?: string;
}

export interface DistributorReportApi {
  id: number;
  name: string;
  date_from: string;
  date_to: string;
  status: string;
  created_at: Record<string, any>;
  parameters: Record<string, any>;
  xlsx_path: string;
  send_to_email_path: string;
  available_types: string;
  download_url: string;
  data: Record<string, any>;
  type: string;
}

export interface DistributorReportProps {
  id?: number;
  name?: string;
  dateFrom?: string;
  dateTo?: string;
  status?: string;
  createdAt?: Date;
  parameters?: Record<string, any>;
  xlsxPath: string;
  sendToEmailPath?: string;
  availableTypes?: null;
  downloadUrl: string;
  data?: Record<string, any>;
  type?: string;
}

export interface DistributorReportFilterData {
  offset?: number;
  limit?: number;
  type?: string;
}

export interface CheckExerciseProps {
  coursePassingId: string;
  exerciseId: string;
  answersData: any;
}
export interface TagUpdateData {
  name: string;
}

export type TagCreateData = TagUpdateData;

export interface TeacherRateUpdateData {
  amount: string;
  classType: string;
  activeFrom: string;
}

export interface TeacherRateCreateData {
  amount: string;
  classType: string;
  activeFrom: string;
  languageId: string;
  teacherId: string;
}

export interface SurchargeCreateData {
  amount: string;
  groupId: string;
  type: string;
  activeFrom: Date;
}

export interface Country {
  id: string;
  nativeName: string;
  ruName: string;
}

export interface City {
  id: string;
  name: string;
}

export interface Degree {
  id: string;
  name: string;
}

export type GetCompaniesReport = {
  dateFrom: string;
  dateTo: string;
  companyId?: string;
  offset?: number;
  limit?: number;
};

export type GetCancellationReport = {
  dateFrom: string;
  dateTo: string;
  companyId?: string;
  teacherId?: string;
  offset?: number;
  limit?: number;
};

export type CheckPromocodeData = {
  id: string;
  promocode: string;
  expiredAt: string | null;
};

export type CheckPromocode =
  | { kind: "ok"; data: CheckPromocodeData }
  | GeneralApiProblem;

export type GetTeacherRates = {
  teacherId: string | string[];
  classType?: "webinar" | "face2face";
  offset?: number;
  limit?: number;
};
