import { createInstance } from "i18next";

import en from "./en";
import ru from "./ru";
import ur from "./ur";
import uz from "./uz";

const i18n = createInstance({
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
  nsSeparator: ":",
  resources: { en, ru, ur, uz },
});

i18n.init();

export default i18n;
