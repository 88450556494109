import { useEffect, useMemo } from "react";

import { Box, ThemeProvider, CssBaseline } from "@mui/material";
import { computed } from "mobx";
import { observer } from "mobx-react-lite";
import { useForm } from "react-hook-form";
import { useTranslation, I18nextProvider } from "react-i18next";

import { defaultTheme } from "@packages/shared/themes/default";
import { useStores } from "@packages/store/models";
import { LearningGroup } from "@packages/store/models/LearningGroups/LearningGroupModel";
import { GetLearningGroupsParams } from "@packages/store/services/Api";

import { GroupDownload } from "./components/GroupDownload";
import { GroupDateRangeFilter, GroupFilter } from "./components/GroupFilter";
import { GroupList } from "./components/GroupList";
import { GroupsWithoutPriceDownload } from "./components/GroupsWithoutPriceDownload";
import { Loading } from "./components/Loading";
import { PageBlock } from "./components/PageBlock";
import { Title } from "./components/Title";
import {
  convertObjToURLQueryParams,
  formatGroups,
  sortGroupsByAlphabet,
} from "./helpers";
import { getDefaultValues } from "./helpers/getDefaultValues";
import { useCancelableFetch } from "./hooks";
import i18n from "./locales/i18n";
import { Wrapper } from "./styledComponents/Wrapper";

const getCorrectDate = (date: Date | null | undefined): Date | null => {
  const correctDate = date ? new Date(date) : null;
  correctDate?.setHours(0);
  correctDate?.setMinutes(0);
  correctDate?.setSeconds(0);
  return correctDate;
};

export interface Filters {
  startDate: Date | null;
  endDate: Date | null;
  company: string | null;
  module: string;
  student: string;
  group: string;
  level: string;
  teacher: string;
  inactiveGroups: string;
  withoutTeachers: boolean;
  aboveTwoLessonsEarlyCanceledBySchoolInLastMonth: boolean;
}

interface GroupsProps {
  showReport: (id: string) => void;
  searchParams: URLSearchParams;
  syncSearchParams: (data: Filters) => void;
}

export const Groups = observer((props: GroupsProps): JSX.Element => {
  const { showReport, searchParams, syncSearchParams } = props;

  const { t } = useTranslation();

  const { course, learningGroup, customerCompany, auth, api } = useStores();
  const { items: learningGroups } = learningGroup;
  const { items: courses } = course;
  const { user } = auth;
  const { isDistributor = false, isCoordinator = false } = user ?? {};
  const isCoordinatorOrDistributor = isCoordinator || isDistributor;

  const methods = useForm<Filters>({
    mode: "onChange",
    defaultValues: getDefaultValues(searchParams),
  });
  const { watch } = methods;
  const data = watch();

  const {
    startDate,
    endDate,
    company,
    group,
    module,
    level,
    student,
    teacher,
    inactiveGroups,
    withoutTeachers,
    aboveTwoLessonsEarlyCanceledBySchoolInLastMonth,
  } = data;

  const dateRange = useMemo(
    () => ({ startDate, endDate }),
    [endDate, startDate]
  );

  const { loading: groupsLoading, fetch: fetchGroups } = useCancelableFetch();
  const { loading: coursesLoading, fetch: fetchCourses } = useCancelableFetch();

  const loading = groupsLoading;

  const hasPrimaryFilters = Boolean(
    company ||
      group ||
      teacher ||
      withoutTeachers ||
      aboveTwoLessonsEarlyCanceledBySchoolInLastMonth
  );

  const showSecondaryFilters = isCoordinatorOrDistributor
    ? hasPrimaryFilters
    : true;

  const listModules = [
    ...course.items.map((item) => ({
      label: item.nameTranslated,
      value: item.id,
    })),
    {
      label: t("GroupFilter:AllModules"),
      value: "",
    },
  ];

  const listCompanies = [
    ...customerCompany.items.map((item) => ({
      label: item.name as string,
      value: item.id,
    })),
  ];

  const filters: GetLearningGroupsParams = useMemo(
    () => ({
      includeLessons: true,
      ...(company && { company }),
      ...(teacher && { teacher }),
      ...(group && { name: group }),
      withoutTeachers,
      aboveTwoLessonsEarlyCanceledBySchoolInLastMonth,
    }),
    [
      company,
      teacher,
      group,
      withoutTeachers,
      aboveTwoLessonsEarlyCanceledBySchoolInLastMonth,
    ]
  );

  const groups = computed(() => {
    const filterByCurrentTeacher = (group: LearningGroup) => {
      const { id = "", isTeacher = false } = user ?? {};

      if (!isTeacher) {
        return true;
      }

      return group.teachers.some((teacher) => teacher.actualId === id);
    };

    const filterByCompany = (group: LearningGroup) => {
      if (!company) {
        return true;
      }
      return company === group?.customerCompany?.id;
    };

    const filterByModule = (group: LearningGroup) => {
      if (!module) {
        return true;
      }
      const ids = group.courses.map((c) => c.id);
      return ids.includes(module);
    };

    const filterByLevel = (group: LearningGroup) => {
      if (!level) {
        return true;
      }
      const levels = group.courses.map((c) => c.knowledgeLevel?.code);
      return levels.includes(level);
    };

    const filterByGroup = (item: LearningGroup) => {
      if (!group) {
        return true;
      }
      return item.name?.toLowerCase().includes(group?.toLowerCase());
    };

    const filterByTeacher = (item: LearningGroup) => {
      if (!teacher) {
        return true;
      }
      const ids = item.teachers.map(({ actualId }) => actualId);
      return ids.includes(teacher);
    };

    const filterByStudent = (group: LearningGroup) => {
      if (!student) {
        return true;
      }
      const names = group.students.map((s) => `${s.firstName} ${s.lastName}`);
      return (
        names.filter((name) => {
          return name.toLowerCase().includes(student?.toLowerCase());
        }).length > 0
      );
    };

    const filterByActiveGroups = (group: LearningGroup) => {
      const { status } = group;
      if (!status) {
        return false;
      }
      const isInactive = ["filling", "archived", "disbanded"].includes(status);
      return inactiveGroups ? isInactive : !isInactive;
    };

    const filterByDistributor = () => {
      if (!isCoordinatorOrDistributor) {
        return true;
      }
      return hasPrimaryFilters;
    };

    const filterByDateRange = (group: LearningGroup) => {
      const { from, to } = group.lessonsPeriod;
      const { startDate, endDate } = dateRange;
      const correctStartDate = getCorrectDate(startDate);
      const correctEndDate = getCorrectDate(endDate);
      const correctFrom = getCorrectDate(from);
      const correctTo = getCorrectDate(to);

      if (!correctStartDate && !correctEndDate) {
        return true;
      }

      const isStartCollide = correctFrom
        ? (correctStartDate && correctFrom >= correctStartDate) ||
          (correctEndDate && correctFrom <= correctEndDate)
        : true;
      const isEndCollide = correctTo
        ? (correctStartDate && correctTo >= correctStartDate) ||
          (correctEndDate && correctTo <= correctEndDate)
        : true;

      return isStartCollide || isEndCollide;
    };

    const filterByDisbanced = (group: LearningGroup) => {
      return group.status !== "disbanded";
    };

    const filterByCountInDateRange = (group: LearningGroup) => {
      if (!dateRange.startDate || !dateRange.endDate) return true;

      return group.getLessonsCountInDateRange(dateRange) > 0;
    };

    return learningGroups
      .filter(filterByDisbanced)
      .filter(filterByCountInDateRange)
      .filter(filterByCurrentTeacher)
      .filter(filterByCompany)
      .filter(filterByModule)
      .filter(filterByLevel)
      .filter(filterByGroup)
      .filter(filterByStudent)
      .filter(filterByTeacher)
      .filter(filterByActiveGroups)
      .filter(filterByDistributor)
      .filter(filterByDateRange)
      .sort(sortGroupsByAlphabet);
  }).get();

  const formattedGroups = computed(() => formatGroups(groups, dateRange)).get();

  const titleForModule = (): string => {
    const findedModule = courses.find(({ id }) => id === module);
    return findedModule ? `- ${findedModule.nameTranslated}` : "";
  };

  const showAttendance = (groupId: string) => () => {
    if (!groupId) {
      return;
    }

    const queryParamsObj = {
      groupId,
      ...(startDate && { startDate: startDate.toString() }),
      ...(endDate && { endDate: endDate.toString() }),
    };

    window.open(
      `/attendance-report?${convertObjToURLQueryParams(queryParamsObj)}`,
      "_blank"
    );
  };

  // Запрос групп
  useEffect(() => {
    if (isCoordinatorOrDistributor && !hasPrimaryFilters) {
      return;
    }

    // https://jira.jetclass.ru/browse/JS-1916 https://jira.jetclass.ru/browse/JS-1950
    if (
      isCoordinatorOrDistributor &&
      (withoutTeachers || aboveTwoLessonsEarlyCanceledBySchoolInLastMonth)
    ) {
      learningGroup.clear();
    }

    fetchGroups((token) => api.getLearningGroups(filters, token));
  }, [
    isCoordinatorOrDistributor,
    filters,
    hasPrimaryFilters,
    fetchGroups,
    api,
    withoutTeachers,
    aboveTwoLessonsEarlyCanceledBySchoolInLastMonth,
    learningGroup,
  ]);

  // Синхронизация с url параметрами
  useEffect(() => {
    syncSearchParams(data);
  }, [data, syncSearchParams]);

  useEffect(() => {
    fetchCourses((token) => api.getCourses(token));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ThemeProvider theme={defaultTheme}>
      <I18nextProvider i18n={i18n}>
        <Wrapper>
          <PageBlock>
            <Title titleForModule={titleForModule()} />

            <form>
              <Box
                display="flex"
                justifyContent="space-between"
                gap="1rem"
                flexWrap="wrap"
                my="1rem"
                py="0.5rem"
              >
                <GroupDateRangeFilter methods={methods} />
                <Box display="flex" gap="1rem">
                  <GroupsWithoutPriceDownload data={groups} range={dateRange} />
                  <GroupDownload data={formattedGroups} />
                </Box>
              </Box>
              <GroupFilter
                methods={methods}
                listModules={listModules}
                listCompanies={listCompanies}
                showCompanyFilter={!user?.isHR}
                showSecondaryFilters={showSecondaryFilters}
                showTeacherFilter={!!isCoordinatorOrDistributor}
                showWithoutTeachersFilter={!!isCoordinatorOrDistributor}
                showAboveTwoLessonsEarlyCanceledBySchoolInLastMonth={
                  !!isCoordinatorOrDistributor
                }
                coursesLoading={coursesLoading}
              />
            </form>
            <Loading loading={loading}>
              <GroupList
                data={formattedGroups}
                showReport={(id) => () => showReport(id)}
                showAttendance={showAttendance}
              />
            </Loading>
          </PageBlock>
        </Wrapper>
      </I18nextProvider>

      <CssBaseline />
    </ThemeProvider>
  );
});
